import React, { useContext, useEffect } from "react"

import AuthContext from '../context/AuthProvider';
import { getStorage, setStorage, getQuerystringValue } from '../helpers/general'

import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/organisms/Seo/Seo"
import LoginForm from "../components/organisms/LoginForm/LoginForm"
import Container from "../components/atoms/Container/Container"
import Button from "../components/atoms/Button/Button"
import IndividualContentBlock from "../components/atoms/IndividualContentBlock/IndividualContentBlock"

import styles from './login.module.css'

const LoginPage = () => {
    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    let redirectTo = '/account';
    const afterAuthCalls = getStorage('_afterAuth');
    let afterAuthCallsObject = {};
    if (afterAuthCalls) {
        afterAuthCallsObject = JSON.parse(afterAuthCalls);
    }

    if (typeof window !== 'undefined' && getQuerystringValue('redirectTo')) {
        redirectTo = getQuerystringValue('redirectTo');
        setStorage('forcedPage', `${window.location.origin}${redirectTo}`, true);
    }

    useEffect(() => {
        if (isLoggedIn && typeof window !== 'undefined') {
            // console.log("Logged in already");
            window.location = redirectTo;
        }
    }, [isLoggedIn, redirectTo])

    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <SEO title="Login" />
            <div className={`${styles.loginGrid} grid grid-50`}>
                <div className={`${styles.loginForm} center`}>
                    <Container>
                        <IndividualContentBlock sectionKey="Login section content" />
                        {'action' in afterAuthCallsObject && afterAuthCallsObject.action === 'saveWishlist' && (
                            <div className={styles.notice}>
                                An account is required to save a wishlist. Please login or sign up for a free account to save your wishlist.
                            </div>
                        )}
                        <LoginForm />
                    </Container>
                </div>

                <div className={styles.createAccount}>
                    <Container>
                        <IndividualContentBlock sectionKey="New Customer section content" />
                        <div className={styles.formActions}>
                        <Button href="/signup/" level="primary">Create account</Button>
                        </div>
                    </Container>
                </div>
            </div>
        </Layout>
        )
    } else {
        return null;
    }
  }
  
  export default LoginPage
  
import React, { useState, useContext } from 'react'
import { Link } from 'gatsby';

import AuthContext from '../../../context/AuthProvider';

import Button from "../../atoms/Button/Button"
import Checkbox from "../../atoms/Checkbox/Checkbox"
import Dialog from "../../atoms/Dialog/Dialog"

import styles from './LoginForm.module.css'

const LoginForm = () => {
    const auth = useContext(AuthContext);
    const handleLogin = auth && auth.login;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(true);
    const [dialogMessage, setDialogMessage] = useState(false);

    const attemptLogin = (e) => {
        e.preventDefault();
        handleLogin(email, password, remember).then(() => {
            // console.log(response);
        }).catch(error => {
            console.log(error);
            setDialogMessage(error);
        });
    }

    const clearDialog = () => {
        setDialogMessage(false);
    }

    const onChangeCheckbox = (e) => {
        return setRemember(e.target.checked)
    }

    return (
        <form onSubmit={(e) => attemptLogin(e)} className={styles.loginForm}>
            <div className="formField">
                <input className={styles.loginFields} type="email" placeholder="Email Address" name="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="formField">
                <input className={styles.loginFields} type="password" placeholder="Password" name="password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className={`${styles.formRemember} formField`}>
                <Checkbox
                name="rememberme"
                label="Remember me"
                action={(e) => onChangeCheckbox(e)}
                isChecked={remember}
                />
                <Link to="/forgotpassword/">Forgot password?</Link>
            </div>
            <div className={`${styles.formActions} formField`}>
                <Button level="primary" type="buttonSubmit">Login</Button>
            </div>
            <Dialog open={dialogMessage ? true : false} title="Login unsuccessful" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>{dialogMessage}</Dialog>
        </form>
    )
}

export default LoginForm